import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { destroyCookie } from "nookies";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
};

const APP_URL = process.env.NEXT_PUBLIC_APP_URL;

const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();

export const initializeApp = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    if (typeof window !== "undefined") {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    }
  } else {
    firebase.app(); // if already initialized, use that one
  }
};

export const signInWithGoogle = async () => {
  try {
    return await firebase.auth().signInWithPopup(googleProvider);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const signInWithFacebook = async () => {
  try {
    return await firebase.auth().signInWithPopup(facebookProvider);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const signInWithEmailAndPassword = async (email, password) => {
  try {
    return await firebase.auth().signInWithEmailAndPassword(email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
    throw err;
  }
};

export const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const userRes = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);

    await Promise.all([
      userRes.user.sendEmailVerification(),
      userRes.user.updateProfile({
        displayName: name,
      }),
    ]);
  } catch (err) {
    console.log("---- error ----");
    console.error(err);
    throw err;
  }
};

export const sendPasswordResetEmail = async (email) => {
  var actionCodeSettings = {
    // After password reset, the user will be give the ability to go back
    // to this page.
    url: `${APP_URL}/`,
    handleCodeInApp: false,
  };
  try {
    await firebase.auth().sendPasswordResetEmail(email, actionCodeSettings);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getFirebaseApp = () => {
  initializeApp();
  return firebase.app();
};

export const signOut = async () => {
  destroyCookie(null, "token");
  return await firebase.auth().signOut();
};

export const getFirebaseAuth = () => {
  return getAuth(getFirebaseApp());
};
