import '../styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/overrides.css';
import { Analytics } from '@vercel/analytics/react';
import GoogleAnalytics from '../components/GoogleAnalytics';
import { SpeedInsights } from '@vercel/speed-insights/next';
import BottomNavBar from '../features/navigation/BottomNavBar';
import React, { Suspense } from 'react';
React.useLayoutEffect = React.useEffect;

import { useState } from 'react';
import { store } from '../features/store';
import { Provider } from 'react-redux';
import { initializeApp } from '../helpers/firebase';
import Header from '../components/Header';
import LoginModal from '../components/LoginModal';
import DiscountModal from '../components/DiscountModal';
import Head from 'next/head';
import LoadingPage from '../components/LoadingPage';
import { ToastContainer } from 'react-toastify';
import AuthProvider from '../helpers/AuthProvider';

function MyApp({ Component, pageProps }) {
  initializeApp();

  const [showLoginModal, setShowLoginModal] = useState(false);

  const toggleLoginModal = () => {
    setShowLoginModal(!showLoginModal);
  };

  return (
    <Provider store={store}>
      <AuthProvider>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
          />
        </Head>
        <Header float onLoginClick={toggleLoginModal} />
        <div className="h-screen w-screen flex justify-center">
          <div className="w-full max-w-7xl">
            <Component {...pageProps} />
          </div>
          {/* TODO: something is wrong when hitting the search button */}
          <BottomNavBar />
        </div>
        {showLoginModal && (
          <LoginModal show={showLoginModal} onClose={toggleLoginModal} />
        )}
        <LoadingPage />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          theme="light"
        />
        <DiscountModal />
      </AuthProvider>
      <Analytics />
      <Suspense>
        <div className="hidden">
          <GoogleAnalytics />
        </div>
        {/* <TikTokAnalytics /> */}
      </Suspense>
      <SpeedInsights />
    </Provider>
  );
}

export default MyApp;
