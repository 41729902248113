import React, { forwardRef, InputHTMLAttributes, LegacyRef } from "react";
import classNames from "classnames";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}
const Input = forwardRef(
  (props: InputProps, ref: LegacyRef<HTMLInputElement>) => {
    return (
      <input
        {...props}
        ref={ref}
        className={classNames(
          "outline-none",
          "appearance-none",
          "border",
          "rounded-md",
          "h-12",
          "border-philippine-silver",
          "px-5",
          "grow",
          "focus:border-copper focus:border",
          "active:border-copper active:border",
          "text-base",
          "text-davys-grey",
          {
            "border-red-700": props.error,
          }
        )}
      />
    );
  }
);

Input.displayName = "Input";

export default Input;
