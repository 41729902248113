import { createContext, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import nookies from 'nookies';
import { getFirebaseAuth } from './firebase';
import { User } from 'firebase/auth';

const auth = getFirebaseAuth();

export const AuthContext = createContext<{
  user: null | undefined | User;
  error: any;
  loading: boolean;
}>({
  user: null,
  loading: false,
  error: '',
});

export default function AuthProvider({ children }: { children: any }) {
  const [authStateUser, loading, error] = useAuthState(auth);

  // handle auth logic here...
  useEffect(() => {
    return firebase.auth().onIdTokenChanged(async (user) => {
      // console.log(`token changed!`);
      if (!user) {
        // console.log(`no token found...`);
        nookies.destroy(null, 'token');
        nookies.set(null, 'token', '', { path: '/' });
        return;
      }

      // console.log(`updating token...`);
      const token = await user?.getIdToken();
      nookies.destroy(null, 'token');
      nookies.set(null, 'token', token, { path: '/' });

      // console.log(`token updated!`);

      const referral = localStorage.getItem('referred_by');
      if (referral) {
        try {
          const res = await fetch('/api/profile/referred-by', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              referredBy: referral,
            }),
          });

          if (!res.ok) {
            throw new Error(res.statusText);
          }

          const data = await res.json();
          localStorage.removeItem('referred_by');
        } catch (e) {
          console.log(e);
        }
      }
    });
  }, []);

  // force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(async () => {
      // console.log(`refreshing token...`);
      const user = firebase.auth().currentUser;
      if (user) {
        await user?.getIdToken(true);
      }
    }, 10 * 60 * 1000);
    return () => clearInterval(handle);
  }, []);

  return (
    <AuthContext.Provider value={{ user: authStateUser, loading, error }}>
      {children}
    </AuthContext.Provider>
  );
}
