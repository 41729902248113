import { Fragment, ReactElement, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { IoMdClose } from 'react-icons/io';
import classNames from 'classnames';

type ModalProps = {
  show: boolean;
  size: 'large' | 'medium' | 'full';
  title?: string;
  onClose: (evt?: any) => void;
  children: ReactNode;
  centeredContent?: boolean;
  hideCloseButton?: boolean;
  showHeader?: boolean;
};
const Modal = ({
  show = false,
  size,
  title = '',
  onClose,
  children,
  centeredContent = false,
  hideCloseButton = false,
  showHeader = false,
}: ModalProps): ReactElement => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 light bg-black bg-opacity-70"
        onClose={onClose}
      >
        <div
          className={`h-full text-center relative ${
            size !== 'full' ? 'px-3' : ''
          }`}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          {centeredContent && (
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
          )}
          {!hideCloseButton && (
            <button onClick={onClose}>
              <IoMdClose
                className={`absolute top-2 right-2 z-50 ${
                  size === 'full' ? 'text-copper' : 'text-white'
                }`}
                size="2em"
              />
            </button>
          )}
          {showHeader && (
            <div
              className={classNames(
                'h-14 bg-copper flex items-center pr-4 pl-1 rounded-xl my-2 w-full justify-between'
              )}
            >
              <button
                className="h-10 w-10 rounded-3xl flex justify-center items-center text-white bg-copper appearance-none"
                onClick={onClose}
              >
                <IoMdClose className="text-2xl" />
              </button>
              <h1 className="text-white font-bold text-2xl cursor-pointer">
                tikipal
              </h1>
            </div>
          )}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={`inline-block w-full my-8 overflow-y-scroll text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl relative items-center justify-center ${
                size == 'large'
                  ? `max-w-7xl max-h-[40rem]`
                  : size == 'medium'
                  ? 'max-w-md max-h-[40rem]'
                  : size == 'full'
                  ? 'w-full h-screen rounded-none my-0 mx-0'
                  : 'max-w-fit my-20'
              }`}
            >
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {title}
              </Dialog.Title>
              <div className="text-sm text-gray-500 space-y-3 flex justify-center">
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
