import React, { ReactElement } from "react";
import { AiOutlineLoading } from "react-icons/ai";

const Spinner = (): ReactElement => {
  return (
    <div className="flex w-full grow justify-center h-40 items-center">
      <AiOutlineLoading className="animate-spin text-copper" size="2em" />
    </div>
  );
};

export default Spinner;
