type GtagParams = Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams;
type GtagAction = Gtag.EventNames | string;

// log specific events
export const logGtagEvent = ({
  action,
  params,
}: {
  action: GtagAction;
  params: GtagParams;
}) => {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
    window && window.gtag && window.gtag('event', action, params);
  }
};
