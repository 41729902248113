import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SpacesStateType = {
  spaces: SpaceType[];
  new: SpaceType[];
  featured: SpaceType[];
};

const spacesInitialState: SpacesStateType = {
  spaces: [],
  new: [],
  featured: [],
};

export const spacesSlice = createSlice({
  name: "spaces",
  initialState: spacesInitialState,
  reducers: {
    setSpaces(state, action: PayloadAction<SpaceType[]>) {
      return {
        ...state,
        spaces: action.payload,
      };
    },
    setNewSpaces(state, action: PayloadAction<SpaceType[]>) {
      return {
        ...state,
        new: action.payload,
      };
    },
    setFeaturedSpaces(state, action: PayloadAction<SpaceType[]>) {
      return {
        ...state,
        featured: action.payload,
      };
    },
  },
});

export const { setSpaces, setFeaturedSpaces, setNewSpaces } =
  spacesSlice.actions;

export default spacesSlice.reducer;
