import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = ({
  type = 'primary',
  size = 'medium',
  shrink = false,
  disabled = false,
  children,
  font = 'bold',
  ...props
}) => {
  return (
    <button
      type="button"
      className={classNames('rounded-md', 'text-sm', 'text-center', {
        'lg:max-w-[15rem] lg:min-w-[10rem] grow w-full': !shrink,
        'w-auto inline-flex': shrink,
        'py-2 px-5 h-10': size !== 'small',
        'py-1 px-4 h-8': size === 'small',
        'bg-copper text-white': type === 'primary',
        'bg-white border-copper text-copper border': type === 'secondary',
        'opacity-70 cursor-not-allowed': disabled,
        'font-bold': font === 'bold',
      })}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['small', 'medium']),
  onClick: PropTypes.func,
};

export default Button;
