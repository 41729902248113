import { configureStore } from "@reduxjs/toolkit";
import spacesSlice from "./spaces/spacesSlice";

export const store = configureStore({
  reducer: {
    spaces: spacesSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
