import * as Yup from "yup";

const registerValidationSchema = Yup.object().shape({
  name: Yup.string().required("Nombre es un campo requerido."),
  email: Yup.string()
    .email("El email ingresado no es válido.")
    .required("Email es un campo requerido."),
  password: Yup.string()
    .required("Contraseña es un campo requerido.")
    .min(8, "La contraseña debe tener al menos 8 caracteres."),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Las contraseñas deben coincidir."
  ),
});

export default registerValidationSchema;
