import classNames from "classnames";
import { useField } from "formik";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import Input from "../Controls/Input";

const InputField = (props: any): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState(false);
  const [field, meta] = useField(props);
  const {
    label,
    disabled,
    className,
    containerClassName,
    errorInline,
    errorBelow,
    errorClassName,
    rightElement,
    labeled,
    placeholder,
    ...rest
  } = props;

  const { touched, error } = meta;
  const { value } = field;
  function _renderHelperText() {
    if (touched && !!error?.length) {
      return (
        <div className="text-red-700 absolute bottom-1 text-xs">{error}</div>
      );
    }
  }

  useEffect(() => {
    const input = inputRef.current;
    const onFocus = () => {
      setFocused(true);
    };
    const onBlur = () => {
      setFocused(false);
    };
    if (input) {
      input.addEventListener("focus", onFocus);
      input.addEventListener("blur", onBlur);
    }
    return () => {
      if (input) {
        input.removeEventListener("focus", onFocus);
        input.removeEventListener("blur", onBlur);
      }
    };
  }, []);

  return (
    <div className="relative flex flex-col pb-6 pt-3 w-full">
      {(labeled && focused) || (labeled && !!value) ? (
        <label
          className={classNames(
            "absolute",
            "top-0",
            "left-4",
            "bg-white",
            "px-1",
            "text-philippine-silver",
            "text-sm",
            {
              "text-copper": focused,
              "text-philippine-silver": !focused,
            }
          )}
        >
          {label}
        </label>
      ) : null}
      <Input
        {...field}
        {...rest}
        placeHolder={!focused ? rest.placeHolder : ""}
        error={meta.error && meta.touched}
        ref={inputRef}
      />
      {_renderHelperText()}
    </div>
  );
};

export default InputField;
