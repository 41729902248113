import { useRouter } from "next/router";
import { ReactElement, useEffect, useState } from "react";
import Spinner from "./Spinner";

const LoadingPage = (): ReactElement | boolean => {
  const router = useRouter();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = (url: string) =>
      url !== router.asPath && setLoading(true);

    const handleComplete = (url: string) =>
      url === router.asPath && setLoading(false);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  });

  return (
    loading && (
      <div className="w-screen h-screen flex flex-col fixed top-0 left-0 z-50 bg-black bg-opacity-60">
        <Spinner />
      </div>
    )
  );
};

export default LoadingPage;
