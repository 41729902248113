import React, { useState, useEffect, useCallback, useRef } from 'react';
import debounce from 'lodash/debounce';
import { AiOutlineLoading } from 'react-icons/ai';

const SearchInput = ({ onSelect }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1); // For keyboard navigation
  const resultsRef = useRef<any>(null); // Reference for the results container
  const inputRef = useRef<HTMLInputElement>(null); // Reference for the input field

  // Debounced function to fetch data
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchResults = useCallback(
    debounce(async (query) => {
      if (query.trim() === '') {
        setResults([]);
        return;
      }

      setLoading(true);
      try {
        console.log('fetching data...');
        const response = await fetch(`/api/spaces/search?name=${query}`);
        const data = await response.json();
        setResults(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setResults([]);
      } finally {
        setLoading(false);
      }
    }, 300), // 300ms debounce delay
    []
  );

  useEffect(() => {
    fetchResults(query);
    // Clean up the debounce when the component is unmounted or the query changes
    return () => fetchResults.cancel();
  }, [query, fetchResults]);

  // Focus on input when the component mounts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  // Scroll to the highlighted item
  useEffect(() => {
    if (
      resultsRef.current &&
      highlightedIndex >= 0 &&
      highlightedIndex < results.length
    ) {
      const highlightedItem = resultsRef.current.children[highlightedIndex];
      highlightedItem.scrollIntoView({ block: 'nearest' });
    }
  }, [highlightedIndex, results]);

  const handleInputChange = (e) => {
    if (e.target.value.trim() === '') {
      setLoading(false);
    } else {
      setLoading(true);
    }
    setQuery(e.target.value);
    setHighlightedIndex(-1); // Reset highlight on input change
  };

  const handleKeyDown = (e) => {
    if (results.length > 0) {
      if (e.key === 'ArrowDown') {
        // Navigate down
        setHighlightedIndex((prevIndex) =>
          prevIndex < results.length - 1 ? prevIndex + 1 : prevIndex
        );
      } else if (e.key === 'ArrowUp') {
        // Navigate up
        setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
      } else if (e.key === 'Enter' && highlightedIndex >= 0) {
        // Trigger onSelect for the highlighted item
        onSelect(results[highlightedIndex]);
      }
    }
  };

  return (
    <div className="relative">
      <input
        type="text"
        ref={inputRef} // Assign the ref to the input field
        value={query}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className="border border-[#FC7851] p-2 rounded-md w-full z-40 focus:border-[#FC7851] focus:outline-none placeholder:text-[#4B4B4B]"
        placeholder="Busca tu lugar favorito"
      />
      {loading && (
        <AiOutlineLoading
          className="animate-spin text-copper absolute right-3 top-[10px]"
          size="1.5rem"
        />
      )}
      {results.length > 0 ? (
        <ul
          ref={resultsRef} // Reference to the results container
          className="max-h-40 h-auto overflow-y-auto border border-t-0 border-[#FC7851] rounded-b-md bg-white z-40 absolute w-full"
        >
          {results.map((result: any, index) => (
            <li
              className={`px-2 py-1 cursor-pointer hover:bg-[#F7F7F7] ${
                highlightedIndex === index ? 'bg-[#F7F7F7]' : ''
              }`}
              key={result.id}
              onClick={() => onSelect(result)}
              onMouseEnter={() => setHighlightedIndex(index)} // Highlight on mouse enter
            >
              {result.name}
            </li>
          ))}
        </ul>
      ) : results.length === 0 && query.trim() !== '' && !loading ? (
        <div className="border border-t-0 border-[#FC7851] rounded-b-md bg-white z-40 absolute w-full">
          <p className="px-2 py-1">No se encontraron resultados</p>
        </div>
      ) : null}
    </div>
  );
};

export default SearchInput;
