import * as Yup from "yup";

const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("El email ingresado no es válido.")
    .required("Email es un campo requerido."),
  password: Yup.string().required("Contraseña es un campo requerido."),
});

export default loginValidationSchema;
