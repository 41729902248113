import React, { ReactElement } from "react";

import Modal from "./Modal";
import LoginSection from "./Login/LoginSection";

type LoginModalProps = {
  show: boolean;
  fullscreen?: boolean;
  onClose: () => void;
  onLogin?: () => void;
};

const LoginModal = ({ show, onClose }: LoginModalProps): ReactElement => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      size="medium"
      hideCloseButton
      showHeader
    >
      <div className="w-full overflow-y-scroll">
        <LoginSection
          onLogin={() => {
            onClose();
          }}
        />
      </div>
    </Modal>
  );
};

export default LoginModal;
