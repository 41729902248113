import React, { ReactElement, use, useEffect, useState } from 'react';
import Modal from './Modal';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseApp, signOut } from '../helpers/firebase';
import Image from 'next/image';
import { FaUserAlt } from 'react-icons/fa';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { useRouter } from 'next/router';
import QRCode from 'react-qr-code';
import Button from './Button';
import { toastSuccess } from './Common/toasters';
import Spinner from './Spinner';

const NEXT_PUBLIC_APP_URL = process.env.NEXT_PUBLIC_APP_URL;

const auth = getAuth(getFirebaseApp());

type UserModalProps = {
  show: boolean;
  onClose: () => void;
};

const UserModal = ({ show, onClose }: UserModalProps): ReactElement => {
  const [user] = useAuthState(auth);

  const [showReferralCode, setShowReferralCode] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const router = useRouter();

  useEffect(() => {
    const fetchProfile = async () => {
      if (!user) return;
      try {
        const res = await fetch('/api/profile');
        if (!res.ok) {
          throw new Error(res.statusText);
        }

        const {
          profile: { referral_code: referralCode },
        } = await res.json();
        setReferralCode(referralCode);
      } catch (e) {
        console.log(e);
      }
    };

    fetchProfile();
  }, [user]);

  const handleLogoutClick = async () => {
    await signOut();
    onClose();
    router.push('/');
  };

  const handleShowTicketsClick = () => {
    router.push('/tickets');
    onClose();
  };

  const toggleShowReferralCode = () => {
    setShowReferralCode((prev) => !prev);
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      size="medium"
      hideCloseButton
      showHeader
    >
      <>
        <div className="relative flex flex-col p-4 items-center">
          <div className="w-full flex items-center border-b border-copper pb-2 mt-2 justify-between">
            <h2 className="text-copper text-lg font-semibold">
              {user?.displayName}
            </h2>
            <div className="h-12 w-12 rounded-full overflow-hidden flex justify-center items-center z-[100] border-copper border">
              {user?.photoURL ? (
                <Image
                  src={user?.photoURL}
                  width={56}
                  height={56}
                  alt="user-photo"
                />
              ) : (
                <div className="h-14 w-14 bg-white flex justify-center items-center">
                  <FaUserAlt width="3.5em" height="3.5em" />
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col py-4 w-full gap-4">
            <button
              className="flex w-full items-center justify-between"
              onClick={handleShowTicketsClick}
            >
              <div className="flex flex-col items-start">
                <div className="text-copper font-semibold text-base">
                  Mis Entradas
                </div>
                <div>Encontraras los tickets que has comprado.</div>
              </div>
              <BsChevronRight className="text-copper text-2xl" />
            </button>
            <button
              className="flex w-full items-center justify-between"
              onClick={toggleShowReferralCode}
            >
              <div className="flex flex-col items-start">
                <div className="text-copper font-semibold text-base">
                  Código de referido
                </div>
                <div>Comparte tu código de referido</div>
              </div>
              {showReferralCode ? (
                <BsChevronLeft className="text-copper text-2xl" />
              ) : (
                <BsChevronRight className="text-copper text-2xl" />
              )}
            </button>
            {showReferralCode && (
              <div className="flex flex-col items-center">
                <div className="text-copper font-semibold text-base">
                  {referralCode ? (
                    <QRCode
                      // TODO: get referral code from profile
                      value={`${NEXT_PUBLIC_APP_URL}?referral=${referralCode}`}
                      size={128}
                    />
                  ) : (
                    <Spinner />
                  )}
                </div>
                <div className="flex flex-row w-full items-center gap-4 px-4 py-5">
                  <div className="border-b border-gray-300 flex-grow h-1" />
                  O comparte este link
                  <div className="border-b border-gray-300 flex-grow h-1" />
                </div>
                <div className="flex flex-col w-full items-center gap-4 px-4">
                  <div className="bg-gray-300 p-2 text-xs rounded-md">{`${NEXT_PUBLIC_APP_URL}?referral=${referralCode}`}</div>
                  <Button
                    onClick={() => {
                      // TODO: get referral code from profile
                      navigator.clipboard.writeText(
                        `${NEXT_PUBLIC_APP_URL}?referral=${referralCode}`
                      );
                      toastSuccess('Link de referido copiado al portapapeles');
                    }}
                    type="secondary"
                  >
                    Copiar Link
                  </Button>
                </div>
              </div>
            )}
            {/* <button
              className="flex w-full items-center justify-between"
              onClick={() => router.push('/collectibles')}
            >
              <div className="flex flex-col items-start">
                <div className="text-copper font-semibold text-base">
                  Coleccionables
                </div>
                <div className="text-left">
                  Coleccionables que has adquirido en la plataforma.
                </div>
              </div>
              <BsChevronRight className="text-copper text-2xl" />
            </button> */}
          </div>
          <div className="pt-4 border-t border-copper w-full flex justify-center">
            <button
              onClick={handleLogoutClick}
              className="underline opacity-70"
            >
              Cerrar Sesión
            </button>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default UserModal;
